<template>
  <div
    class="MessageContainer"
    :class="{
      Pending: message.status === 'pending',
      Typing: message.is_typing,
      ThirdPart: message.third_party,
      Error: message.status === 'error',
      in: direction === 'in',
      out: direction === 'out',
      edit: !!message.edit,
      CanEdit: canEdit && message.status !== 'error',
      BiggerText: useUIStore().accessibility.biggerText === 1,
      BiggerText2: useUIStore().accessibility.biggerText === 2,
      BiggerText3: useUIStore().accessibility.biggerText === 3,
      HighContrast: useUIStore().accessibility.highContrast,
    }"
  >
    <div class="Message">
      <div
        v-if="!message.edit"
        class="MessageText"
      >
        <template v-if="showIsTypingIcon">
          <icon-is-typing />
        </template>
        <span
          v-else
          class="MessagePre"
          v-html="messageParsed"
        />
      </div>
      <div
        v-else
        class="tw-w-full"
      >
        <el-input
          ref="InputRef"
          v-model="editMessage"
          class="EditInput"
          type="textarea"
          autosize
        />
      </div>
    </div>
    <!-- is not delivered -->
    <div class="MessageThrobber tw-opacity-100 tw-px-4">
      <icon-el-loading
        v-if="message.status === 'pending'"
        class="tw-animate-spin tw-w-4 tw-h-4 [animation-duration:1.5s]"
      />
      <el-tooltip
        v-if="message.status === 'error'"
        :placement="useBreakpoint.smallerOrEqual('lg').value ? 'top' : 'left'"
        :content="$t('ChatMessage.resendTitle')"
      >
        <icon-el-warning-filled
          class="tw-text-danger-500 tw-cursor-pointer tw-w-4 tw-h-4"
          @click="emit('resend-message', message)"
        />
      </el-tooltip>
    </div>
    <!-- is delivered -->
    <template v-if="canEdit && message.status !== 'error'">
      <div
        v-if="!message.edit"
        class="OnHover tw-mx-2"
      >
        <el-button
          size="small"
          circle
          @click="emit('toggle-edit-message')"
        >
          <icon-edit />
        </el-button>
      </div>
      <div
        v-else
        class="tw-flex tw-flex-row-reverse"
      >
        <el-button
          class="tw-mr-3"
          type="default"
          circle
          plain
          @click="emit('toggle-edit-message')"
        >
          <icon-close />
        </el-button>
        <el-button
          class="tw-mr-3"
          type="success"
          circle
          @click="emit('edit-message', { ...message, content: editMessage })"
        >
          <icon-check />
        </el-button>
      </div>
    </template>
  </div>
</template>

<script setup>
const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
  direction: {
    type: String,
    validator: (value) => ['in', 'out'].includes(value),
    required: true,
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
  showIsTypingIcon: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['resend-message', 'toggle-edit-message', 'edit-message']);

// Refs
const editMessage = ref('');
const InputRef = ref(null);

// Computed
const messageParsed = computed(() => {
  const message = wrapUrl(props.message.content);

  if (useAuthStore().user?.is_operator) {
    return wrapPhone(message);
  }

  return message;
});

// Methods
const wrapUrl = (message = '') => {
  if (!message.includes('//')) {
    return message;
  }

  const urlPattern = /(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)(?:\.(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)*(?:\.(?:[a-z\x{00a1}\-\x{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?/ig;

  return message.replace(urlPattern, (url) => {
    const protocolPattern = /^(?:(?:https?|ftp):\/\/)/i;
    const href = protocolPattern.test(url) ? url : 'http://' + url;
    return `<a href="${href}" target="_blank">${url}</a>`;
  });
};

const wrapPhone = (message = '') => {
  const phonePattern = /(?:[-+00() ]*\d){9,13}/g;

  return message.replace(phonePattern, (value) => {
    const phone = value.replace(/ /, '');
    return ` <a href="javascript:window.openCallingBox('${phone}')">${phone}</a>`;
  });
};

// Watch
watch(() => props.message.edit, (value) => {
  if (value) {
    editMessage.value = props.message.content;
    nextTick(() => {
      InputRef.value?.focus();
    });
  }
});
</script>

<style scoped>
.MessageContainer {
  display: flex;
  align-items: center;

  &.Pending,
  &.Error {
    .Message {
      opacity: 0.5;
    }
  }

  &.Typing {
    .Message {
      @keyframes typing-animation {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0.7;
        }

        100% {
          opacity: 1;
        }
      }

      animation: typing-animation 2s;
      animation-iteration-count: infinite;
    }
  }

  &.CanEdit:hover {
    .MessageThrobber {
      display: none;
    }
  }

  &.edit {
    .MessageThrobber {
      display: none;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  .MessageThrobber {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Message {
    display: flex;
    padding: 8px 16px;
    min-height: 44px;
    border-radius: 12px;
    align-items: center;

    .MessageText {
      padding: 0;

      svg {
        width: 30px;
        height: 20px;
        margin-top: 5px;
      }

      .MessagePre {
        word-break: break-word;
        white-space: pre-wrap;
      }
    }
  }

  &.in {
    flex-direction: row;

    .Message {
      @apply tw-text-white;

      @apply tw-bg-primary-500;
      border-radius: 2px 12px 12px 2px;

      :deep(a) {
        @apply tw-text-white;
      }
    }

    &.HighContrast {
      .Message {
        @apply tw-bg-primary-700;
      }
    }

    &:first-of-type {
      .Message {
        border-top-left-radius: 12px;
      }
    }

    &:last-of-type:not(:only-child) {
      .Message {
        border-bottom-left-radius: 12px;
      }
    }
  }

  &.out {
    flex-direction: row-reverse;

    &:not(.edit) {
      .Message {
        @apply tw-text-dark;
        background-color: #f3f3f3;
        border-radius: 12px 2px 2px 12px;
      }

      &:first-of-type {
        .Message {
          border-top-right-radius: 12px;
        }
      }

      &:last-of-type:not(:only-child) {
        .Message {
          border-bottom-right-radius: 12px;
        }
      }

      &.HighContrast {
        .Message {
          @apply tw-text-black;
          background-color: #f7f7f7;
        }
      }
    }

    &.edit {
      .Message {
        @apply tw-border tw-border-primary-500;
        border-radius: 2px;

        .EditInput {
          border: none;
          line-height: 24px;
          font-size: 16px;

          :deep(textarea) {
            box-shadow: none;
          }

          &:focus {
            outline: none;
          }
        }
      }

    }
  }

  &.ThirdPart {
    &:not(.edit) {
      .Message {
        @apply tw-bg-info;
        @apply tw-text-white;

        :deep(a) {
          @apply tw-text-white;
        }
      }

      &.HighContrast {
        .Message {
          background-color: #003d9b; /* 30% darken color-info */
        }
      }
    }
  }

  &.BiggerText {
    .MessagePre {
      font-size: 19px;
    }
  }

  &.BiggerText2 {
    .MessagePre {
      font-size: 22px;
    }
  }

  &.BiggerText3 {
    .MessagePre {
      font-size: 30px;
    }
  }

  .OnHover {
    display: none;
  }

  &:hover .OnHover {
    display: block;
  }
}
</style>

<style>
.edit {
  .Message {
    @apply tw-border tw-border-primary-500;
    border-radius: 2px;
    width: 100%;
    padding: 0 !important;

    .EditInput.el-textarea {
      height: 100%;
      display: flex;

      textarea {
        padding: 0 16px;
        flex: 1 0 auto;
        border: none;
        line-height: 24px;
        font-size: 16px;
        @apply tw-rounded-xl;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>
