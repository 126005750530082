<template>
  <div
    class="Chat"
    :class="{
      'Slim': slim,
      'Chat--safari': isSafari,
      'Chat--hasThirdPartMessageSwitch': hasThirdPartMessageSwitch,
      'Chat--canShowPredefinedMessages': canShowPredefinedMessages,
      'BiggerText': useUIStore().accessibility.biggerText === 1,
      'BiggerText2': useUIStore().accessibility.biggerText === 2,
      'BiggerText3': useUIStore().accessibility.biggerText === 3,
    }"
    :style="!isSafari ? `max-height: ${height - 115}px` : false"
  >
    <div
      ref="feed"
      class="ChatDivWrapper"
    >
      <div
        v-if="!isScrolledToBottom"
        class="tw-flex tw-w-full tw-justify-center ScrollToBottomBtnWrapper"
      >
        <el-button
          type="primary"
          circle
          class="hover:tw-shadow-base ScrollToBottomBtn"
          @click="scrollToBottom"
        >
          <icon-caret-down-rounded />
        </el-button>
      </div>
      <div class="ChatDiv">
        <div
          v-if="!messageBlocks.length"
          class="color-muted tw-text-center tw-text-sm"
        >
          {{ $t('ChatFeed.emptyState') }}
        </div>
        <div
          v-for="(block, index) in messageBlocks"
          :key="block.id"
          class="MessageBlock"
          :class="[block.direction]"
        >
          <div class="MessageBlockContent">
            <div
              v-if="block.direction === 'in' || !slim"
              class="MessageBlockSidePanel"
            >
              <el-tooltip
                :content="block.third_party ? $t('ChatFeed.thirdParty') : block.user.name"
              >
                <UserAvatar
                  v-if="block.direction === 'in' && !block.third_party"
                  :user="block.user"
                />
                <div
                  v-else
                  class="UserAvatar"
                  :class="{
                    'UserAvatar--ThirdParty': block.third_party,
                  }"
                >
                  <icon-phone
                    v-if="block.third_party"
                    class="color-muted"
                  />
                </div>
              </el-tooltip>
            </div>
            <div class="Messages">
              <el-tooltip
                v-for="(message) in block.messages"
                :key="message.id"
                :content="getTooltipContent(block, message)"
                :placement="block.direction === 'in' ? `left` : 'right'"
                transition="none"
                :disabled="message.edit"
              >
                <ChatMessage
                  :message="message"
                  :direction="block.direction"
                  :can-edit="canEdit && !block.is_typing"
                  :show-is-typing-icon="block.is_typing && showIsTypingIcon && block.direction === 'out'"
                  @toggle-edit-message="toggleEditMessage(message)"
                  @resend-message="resendMessage(message)"
                  @edit-message="editMessage"
                />
              </el-tooltip>
            </div>
          </div>
          <div class="MessageBlockFooter">
            <div
              v-if="(index == messageBlocks.length - (messagesIsTyping.length !== 0 ? 2 : 1)) || block.is_typing"
              :key="index"
              class="BlockLast tw-text-sm"
            >
              <span class="tw-font-semibold">
                <template v-if="block.direction === 'in'">
                  <template v-if="block.third_party">
                    {{ $t('ChatFeed.thirdParty') }}
                  </template>
                  <template v-else>
                    {{ block.user.name ? block.user.name : `${block.user.first_name} ${block.user.last_name}` }}
                  </template>
                </template>
              </span>
              <span
                v-if="block.is_typing && block.direction === 'in'"
                class="tw-text-muted tw-ml-1"
              >
                {{ $t('ChatFeed.isTyping') }}
              </span>
              <span
                v-if="!block.is_typing"
                class="Timestamp tw-text-muted"
              >
                {{ block.tooltip_time }}
              </span>
            </div>
          </div>
        </div>

        <!-- <template v-if="requestState === 3">
          <div class="tw-flex tw-items-center tw-flex-col tw-mt-8">
            <div class="like-h4 tw-text-text-light tw-mb-3">
              {{ request.service_id === 1 ? 'Tlumočník' : 'Přepisovatel' }} pozastavil požadavek.
            </div>
            <div class="tw-text-sm tw-text-text-light">
              Vyčkejte než se opět připojí.
            </div>
          </div>
        </template> -->
        <template v-if="requestState === 4">
          <div class="tw-flex tw-items-center tw-flex-col tw-mt-8">
            <div class="like-h4 tw-text-text-light tw-mb-3">
              {{ $t('ChatFeed.requestWasClosed') }}
            </div>
            <div class="tw-text-sm tw-text-text-light">
              {{ finalizedAt ? dayjs(finalizedAt).format('DD. MM. YYYY, HH:mm') : '' }}
            </div>
          </div>
        </template>
        <template v-if="requestState === 5">
          <div class="tw-flex tw-items-center tw-flex-col tw-mt-8">
            <div class="like-h4 tw-text-text-light tw-mb-3">
              {{ $t('ChatFeed.requestWasClosed') }}
            </div>
            <div class="tw-text-sm tw-text-text-light">
              {{ finalizedAt ? dayjs(finalizedAt).format('DD. MM. YYYY, HH:mm') : '' }}
            </div>
          </div>
        </template>
        <template v-if="requestState === 4 || requestState === 5">
          <div class="tw-flex tw-justify-center tw-mb-6 tw-mt-6">
            <strong
              v-if="isHistorySent"
              class="tw-text-primary-500 tw-block tw-mt-3 tw-mb-3"
            >
              {{ $t('ChatFeed.chatHistoryWasSentTo') }} {{ useAuthStore().user?.email }}
            </strong>
            <el-button
              v-else-if="!useAuthStore().user?.is_operator && !useAuthStore().user?.is_temp"
              type="default"
              size="small"
              :loading="isHistorySending"
              @click="sendHistoryToEmail"
            >
              {{ $t('ChatFeed.sentChatHistory') }}
            </el-button>
          </div>
        </template>
        <div
          v-if="canShowPredefinedMessages && isPredefinedMessagesShown"
          class="tw-absolute tw-bottom-3.5 tw-left-0 tw-pl-2 tw-pr-6 tw-flex tw-flex-col tw-items-start tw-gap-1.5 tw-w-full"
        >
          <button
            v-for="(predefinedMessage, index) in predefinedMessages"
            :key="index"
            class="tw-block tw-max-w-full tw-text-xs tw-whitespace-nowrap tw-bg-light tw-truncate tw-rounded-lg tw-py-1 tw-px-1.5 tw-transition hover:tw-bg-border"
            :title="predefinedMessage"
            @click="messageToSend = predefinedMessage; $refs.input.focus()"
          >
            {{ predefinedMessage }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!request || (request?.state_id === 1 || request?.state_id === 2)"
      class="InputDiv"
    >
      <div
        v-if="canShowPredefinedMessages"
        class="tw-absolute tw-left-1 -tw-top-8"
      >
        <button
          class="tw-bg-white tw-rounded-full tw-p-1"
          @click="isPredefinedMessagesShown = !isPredefinedMessagesShown"
        >
          <icon-dots-horizontal class="tw-text-text-light tw-w-5 tw-h-auto" />
        </button>
      </div>
      <div
        v-if="hasThirdPartMessageSwitch"
        class="ThirdPartSwitch"
      >
        <el-switch
          v-model="isMessageByThirdParty"
          :width="36"
          :active-text="$t('ChatFeed.thirdPartySpeaking')"
          :inactive-text="$t('ChatFeed.transcriberSpeaking')"
        />
      </div>

      <textarea
        ref="input"
        v-model="messageToSend"
        :placeholder="$t('ChatFeed.messagePlaceholder')"
        data-cy="chat-input"
        @input="submitIsTypingMessage"
        @keyup.enter.exact="submitMessage"
        @keyup.ctrl.enter.exact="messageToSend = messageToSend + '\n'"
      />
      <div class="SubmitMessageButtonWrapper tw-flex">
        <el-button
          type="primary"
          circle
          data-cy="chat-submit"
          :class="isMessageByThirdParty ? 'tw-bg-info' : ''"
          :disabled="disabledSubmit"
          @click="submitMessage"
        >
          <icon-right />
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    request: {
      type: Object,
      default: () => {},
    },
    messages: {
      type: Array,
      default: () => [],
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    slim: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    allowUpload: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['submit-message'],
  setup() {
    const { height } = useWindowSize();

    return {
      height,
    };
  },
  data() {
    return {
      isMessageByThirdParty: false,
      messageToSend: '',
      sendingMessage: false,
      isScrolledToBottom: false,
      isSafari: navigator ? /^((?!chrome|android).)*safari/i.test(navigator.userAgent) : false,
      isHistorySending: false,
      isHistorySent: false,
      isPredefinedMessagesShown: false,
      audio: {
        newMessage: new Audio('/sounds/chat_message.mp3'),
      },
    };
  },
  computed: {
    me() {
      return useAuthStore().user;
    },
    disabledSubmit() {
      return !this.messageToSend || this.submitDisabled;
    },
    finalizedAt() {
      return this.request ? this.request.finalized_at : false;
    },
    showIsTypingIcon() {
      return this.slim || (this.request && !this.request.state_id != 1);
    },
    messagesIsTyping() {
      return this.messages.filter((message) => message.is_typing && message.content);
    },
    hasThirdPartMessageSwitch() {
      return this.request && this.request.service_id === 2 && useAuthStore().user?.is_operator;
    },
    messageBlocks() {
      const blocks = [];

      let block = {
        direction: '',
        messages: [],
      };

      let messages = this.messages.map((m) => ({ ...m })).filter((message) => !message.is_typing);
      messages = [...messages, ...this.messagesIsTyping].filter((message) => !!message.content);

      for (let index = 0; index < messages.length; index++) {
        const message = messages[index];
        const next = messages[index + 1];

        const momentCreatedAt = dayjs(message.created_at);
        message.tooltip_time = momentCreatedAt.isValid()
          ? momentCreatedAt.calendar(null, {
            sameDay: 'HH:mm',
            lastDay: '[včera] HH:mm',
            lastWeek: 'dddd HH:mm',
            sameElse: 'DD.MM.YYYY HH:mm',
          })
          : '';

        block.messages.push(message);
        block.is_typing = block.messages.some((message) => message.is_typing);
        block.third_party = block.messages.some((message) => message.third_party);

        // close the current message block
        if (!next || // if this is the last message
          next.sender.id != message.sender.id || // if the next message is from another user
          next.third_party !== message.third_party || // if the next message is from third party
          dayjs(next.created_at).diff(message.created_at, 'minute') > 5 || // if the delay between the messages is more than 5 mins
          next.is_typing
        ) {
          block.direction = message.sender.id == this.me.id ? 'out' : 'in';
          const name = message.sender.name ? message.sender.name : `${message.sender.first_name} ${message.sender.last_name}`;
          block.user = { ...message.sender, name };
          block.id = message.id;
          block.tooltip_time = message.tooltip_time;
          blocks.push(block);

          block = {
            messages: [],
          };
        }
      }

      return blocks;
    },
    requestState() {
      return this.request ? this.request.state_id : null;
    },
    isMessageByThirdPartySessionKey() {
      return this.request && this.request.id && `isMessageByThirdParty-${this.request.id}`;
    },
    predefinedMessages() {
      return [
        'Dobrý den, můžeme začít?',
        `Bohužel jste se nepřipojil/a, váš požadavek musíme zrušit. Pokud potřebujete ${this.request.service_id === 1 ? 'tlumočit' : 'přepisovat'}, udělejte si prosím nový požadavek.`,
        'Pokud vám Tichá linka nefunguje, podívejte se na videa zde: https://www.tichalinka.cz/technicka-pomoc',
      ];
    },
    canShowPredefinedMessages() {
      return !this.messageToSend && useAuthStore().user?.is_operator && (this.requestState === 1 || this.requestState === 2) && this.request?.client?.lang !== 'ua';
    },
  },
  watch: {
    messageBlocks: {
      handler(newBlocks, oldBlocks) {
        if (this.isScrolledToBottom || !oldBlocks.length) {
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }

        const lastBlock = newBlocks.slice(-1)[0];
        if (lastBlock) {
          if (lastBlock.direction === 'out') {
            // do nothing
          } else {
            // is incoming message
            const windowHidden = document.hidden;

            if ((windowHidden || !this.isVisible) &&
              !lastBlock.is_typing &&
              lastBlock.messages.some((message) => message.isFromWebSocket) &&
              useAuthStore().isLoggedIn) {
              this.audio.newMessage.play().catch((error) => {
                console.warn(error);
              });
            }
          }
        }
      },
      deep: true,
    },
    isVisible: {
      handler() {
        this.scrollToBottom();
      },
      immediate: true,
    },
    'request.id'() {
      this.updateIsMessageByThirdParty();
    },
    isMessageByThirdParty(isMessageByThirdParty) {
      if (isMessageByThirdParty) {
        window.sessionStorage.setItem(this.isMessageByThirdPartySessionKey, true);
      } else {
        window.sessionStorage.removeItem(this.isMessageByThirdPartySessionKey);
      }
    },
  },
  created() {
    this.scrollToBottom();
  },
  mounted() {
    this.scrollToBottom();
    this.$refs.feed.addEventListener('scroll', this.onScroll);

    this.updateIsMessageByThirdParty();
  },
  beforeUnmount() {
    // if there are any messages, take any of them and use it to clear possible edit state
    if (this.messages.length) {
      this.$store.dispatch('chat/clearEditMessage', this.messages[0]);
      this.$refs.feed.removeEventListener('scroll', this.onScroll);
    }
  },
  methods: {
    scrollToBottom() {
      setTimeout(() => {
        if (this.$refs.feed) {
          this.$refs.feed.scrollTop = this.$refs.feed.scrollHeight - this.$refs.feed.clientHeight;
          this.isScrolledToBottom = true;
        }
      }, 50);
    },
    submitMessage() {
      if (!this.disabledSubmit) {
        this.$emit('submit-message', this.messageToSend.trim(), { isTyping: false, isMessageByThirdParty: this.isMessageByThirdParty });
        this.messageToSend = '';
        this.$emit('submit-message', '', { isTyping: true, isMessageByThirdParty: this.isMessageByThirdParty }); // Clear is_typing message
      }
    },
    submitIsTypingMessage: _debounce(function() {
      if (!this.submitDisabled && this.request && this.request.service_id == 2 && (this.request.state_id == 2 || this.request.state_id == 3)) {
        this.$emit('submit-message', this.messageToSend.trim(), { isTyping: true, isMessageByThirdParty: this.isMessageByThirdParty });
      }
    }, 50),
    resendMessage(message) {
      this.$store.commit('chat/MESSAGE_DELETE', message);
      this.$emit('submit-message', message.content, { isTyping: false });
    },
    getTooltipContent(block, message) {
      let content = '';
      const user = block.user;

      if (block.is_typing) {
        content = 'Rozepsaná zpráva';
      } else if (message && message.third_party) {
        return `Volaný, ${block.tooltip_time}`;
      } else if (block.direction === 'in') {
        if (user.is_operator) {
          content = `Operátor ${user.first_name.slice(0, 1)}. ${user.last_name}, ${block.tooltip_time}`;
        } else {
          return `${block.user.name}, ${block.tooltip_time}`;
        }
      } else {
        content = block.tooltip_time;
      }

      return block.tooltip_time || content;
    },
    toggleEditMessage(message) {
      this.$store.dispatch('chat/toggleEditMessage', message);
    },
    editMessage(message) {
      this.$store.dispatch('chat/editMessage', message);
      this.toggleEditMessage(message);
    },
    onScroll: _debounce(function() {
      if (this.$refs.feed) {
        const scrollDelta = Math.abs(this.$refs.feed.scrollTop - (this.$refs.feed.scrollHeight - this.$refs.feed.clientHeight));
        // have some tolerance from the bottom
        this.isScrolledToBottom = scrollDelta < 150;
      }
    }, 50),

    updateIsMessageByThirdParty() {
      if (this.request && this.request.id && window.sessionStorage.getItem(this.isMessageByThirdPartySessionKey)) {
        this.isMessageByThirdParty = true;
      }
    },

    async sendHistoryToEmail() {
      if (this.isHistorySending) {
        return;
      }

      this.isHistorySending = true;

      try {
        await useApi.post(`/client/requests/${this.request.id}/send-chat-history`);
      } catch (error) {
        console.error(error);
        ElMessage.error({
          message: 'Došlo k chybě při odesílání emailu.',
          duration: 5000,
          showClose: true,
        });
      }

      this.isHistorySending = false;
      this.isHistorySent = true;
    },
  },
};
</script>

<style scoped>
.Chat {
  display: block;
  position: relative;
  height: 100%;
  overflow: hidden;

  &.Chat--safari {
    @screen md {
      height: calc(100% - 55px);
    }
  }

  .ScrollToBottomBtnWrapper {
    position: fixed;
    bottom: 0;

    .ScrollToBottomBtn {
      transform: translateX(-50%);
    }
  }

  &.Chat--hasThirdPartMessageSwitch,
  &.Chat--canShowPredefinedMessages {
    .ChatDivWrapper {
      margin-bottom: 82px;
    }
  }

  .ChatDivWrapper {
    position: absolute;
    inset: 0;
    overflow-y: auto;

    padding: 32px 24px;
    padding-bottom: 0;
    margin-bottom: 62px;

    .ChatDiv {
      display: flex;
      flex-direction: column;
    }

    .MessageBlock {
      margin-bottom: 16px;

      .MessageBlockFooter {
        display: flex;

        .BlockLast {
          margin-top: 8px;

          .Timestamp {
            margin-left: 10px;
          }
        }
      }

      .MessageBlockContent {
        display: flex;
        align-items: flex-end;
      }

      .MessageBlockSidePanel {
        display: flex;
        align-items: flex-end;
        height: 100%;
        flex: 0 0 40px;
        margin-right: 16px;

        .UserAvatar {
          position: relative;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          background-size: cover;
          background-position: 50% 50%;
          background-color: #f3f3f3;
          display: flex;
          align-items: center;
          justify-content: center;

          &.online {
            &::after {
              position: absolute;
              bottom: -2px;
              right: -2px;
              content: '';
              height: 12px;
              width: 12px;
              border: 2px solid white;
              border-radius: 50%;
              @apply tw-bg-success;
            }
          }
        }
      }

      &.in {
        padding-right: 56px;

        .MessageBlockFooter {
          .BlockLast {
            margin-left: 56px;
          }
        }

        .MessageBlockSidePanel {
          margin-right: 16px;
        }
      }

      &.out {
        padding-left: 56px;
        align-self: flex-end;
        width: 100%;

        .MessageBlockFooter {
          flex-direction: row-reverse;

          .BlockLast {
            margin-left: 0;
            margin-right: 56px;
          }
        }

        .MessageBlockContent {
          flex-direction: row-reverse;
        }

        .MessageBlockSidePanel {
          margin-right: 0;
          margin-left: 16px;

          .UserAvatar:not(.UserAvatar--ThirdParty) {
            &::before {
              content: 'Vy';
              @apply tw-text-muted;
            }
          }
        }
      }
    }
  }

  .InputDiv {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    min-height: 65px;
    display: flex;
    align-items: center;
    @apply tw-border-t tw-border-border;
    padding-right: 12px;
    background-color: white;
    z-index: 1233;

    .ThirdPartSwitch {
      position: absolute;
      right: 0;
      bottom: 100%;
      @apply tw-bg-white;
      padding: 6px;
      padding-right: 10px;
      opacity: .8;
      margin-bottom: 1px;
    }
  }

  &.Slim {
    .ChatDivWrapper .MessageBlock {
      max-width: 95%;

      &.in {
        padding-right: 0;

        .MessageBlockFooter .BlockLast {
          margin-left: 40px;
        }
      }

      .MessageBlockSidePanel {
        width: 30px;
        margin-right: 0;

        .UserAvatar {
          height: 30px;
          width: 30px;
        }
      }

      &.out {
        padding-left: 0;

        .MessageBlockFooter .BlockLast {
          margin-right: 0;
        }
      }
    }

  }
}
</style>

<style>
.Chat {
  .InputDiv {
    textarea {
      height: 100%;
      display: block;

      flex: 1 0 auto;
      border: none;
      line-height: 24px;
      font-size: 16px;
      max-height: 200px;
      padding: 5px 15px;

      &:focus {
        outline: none;
      }
    }
  }

  &.BiggerText {
    .InputDiv textarea {
      font-size: 19px;
    }
  }

  &.BiggerText2 {
    .InputDiv textarea {
      font-size: 22px;
    }
  }

  &.BiggerText3 {
    .InputDiv textarea {
      font-size: 30px;
    }
  }
}

.is-ios-app {
  .Chat {
    .InputDiv {
      bottom: env(safe-area-inset-top);
    }
  }
}
</style>
